import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { countries as COUNTRIES, languages as LANGUAGES } from "countries-list"
import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react"
import { showToast } from "../../components/status-notification"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { useNode } from "../../hooks/use-node"
import { Item } from "../../types"
import Input from "../layout/form/input"
import Select from "../layout/form/select"
import SubmitButton from "../layout/form/submit-button"
import Textarea from "../layout/form/textarea"
import { useMedia } from "../../hooks/use-media"

export type Props = {
  item: Item
  onUpdate: (item: Item) => void
  onChange?: () => void
}

const ItemEditor: FunctionComponent<Props> = ({ item, onUpdate, onChange }) => {
  const [title, setTitle] = useState(item.title)
  const { numColumns } = useMedia()
  const { rpc } = useNode()

  const onTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value)
  }, [])

  const [image, setImage] = useState(item.image || "")
  const onImageChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setImage(event.currentTarget.value)
  }, [])

  const [description, setDescription] = useState(item.description)
  const onDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(event.currentTarget.value)
    },
    []
  )

  const [isNsfw, setNsfw] = useState(item.is_nsfw)
  const onNsfwChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNsfw(event.currentTarget.checked)
  }, [])

  const [language, setLanguage] = useState(item.language)
  const onLanguageChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setLanguage(event.currentTarget.value.toLowerCase().substring(0, 2))
    },
    []
  )

  const [country, setCountry] = useState(item.country)
  const onCountryChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setCountry(event.currentTarget.value.toUpperCase().substring(0, 2))
    },
    []
  )

  const isPristine = useMemo(
    () =>
      item.title === title &&
      item.image === item.image &&
      item.description === description &&
      item.is_nsfw === isNsfw &&
      item.language === language &&
      item.country === country,
    [
      item.title,
      item.image,
      item.description,
      item.is_nsfw,
      item.language,
      item.country,
      title,
      image,
      description,
      isNsfw,
      language,
      country,
    ]
  )

  const isValid = useMemo(
    () =>
      !isPristine &&
      language.length === 2 &&
      language === language.toLowerCase() &&
      country.length === 2 &&
      country === country.toUpperCase(),
    [isPristine, language, country]
  )

  const { user, updateBalance } = useUser()
  const [isLoading, setLoading] = useState(false)
  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()

      if (user && !isLoading) {
        setLoading(true)

        let editedItem = item
        item.title = title
        item.description = description
        item.image = image
        item.language = language
        item.country = country

        let payload = {
          id: item.id,
          title: title,
          description: description,
          image: image,
          language: language,
          country: country,
        }

        rpc(
          "edit_content",
          JSON.stringify(payload),
          user,
          getCachedSecret(),
          function (res) {
            let amount = 1
            let message
            if (res.status == "ok") {
              message = "Edit sent"
              console.log(res.data.balance)
              onUpdate(editedItem)
              if (user) updateBalance(-0.01)
            } else message = "Edit failed"

            showToast(faInfoCircle, "Smartlike network", message)
          }
        )

        setLoading(false)

        if (onChange) {
          onChange()
        }
      }
    },
    [
      user?.id,
      item.id,
      item.title,
      item.image,
      item.description,
      item.is_nsfw,
      item.language,
      item.country,
      onUpdate,
      title,
      image,
      description,
      isNsfw,
      language,
      country,
      isLoading,
    ]
  )

  const languages = useMemo(() => {
    let l = Object.entries(LANGUAGES)
      .map(([k, v]) => ({
        code: k,
        name: v.name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    l.push({ code: "", name: "" })
    return l
  }, [])

  const countries = useMemo(() => {
    let c = Object.entries(COUNTRIES)
      .map(([k, v]) => ({
        code: k,
        name: v.name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    c.push({ code: "", name: "" })
    return c
  }, [])

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            if (onChange) onChange()
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      <form onSubmit={onSubmit}>
        <div>
          <h2>Title</h2>
          <Input value={title} onChange={onTitleChange} />
        </div>

        <div>
          <h2>Image</h2>
          <Input value={image} onChange={onImageChange} />
        </div>

        <div>
          <h2>Description</h2>
          <Textarea
            value={description}
            onChange={onDescriptionChange}
            rows={6}
          />
        </div>
        <div>
          <div className={numColumns == 1 ? "" : "misc"}>
            <div>
              <h3>Language</h3>
              <Select
                value={language}
                onChange={onLanguageChange}
                style={{ width: "100%" }}
              >
                {languages.map(c => (
                  <option key={c.code} value={c.code}>
                    {c.name}
                  </option>
                ))}
              </Select>
            </div>

            <div className={numColumns == 1 ? "m-form" : ""}>
              <h3>Country</h3>
              <Select
                value={country}
                onChange={onCountryChange}
                style={{ width: "100%" }}
              >
                {countries.map(c => (
                  <option key={c.code} value={c.code}>
                    {c.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div>
          <a href={item.url} target="_blank">
            source
          </a>

          <footer>
            <SubmitButton
              disabled={isLoading /* || !isValid*/}
              loading={isLoading}
            >
              Submit
            </SubmitButton>
          </footer>
        </div>
      </form>

      <style jsx>{`
        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h1 {
          font-weight: 500;
        }
        h2 {
          font-size: inherit;
          font-weight: 500;
        }
        h3 {
          font-size: inherit;
          font-weight: 500;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
        footer {
          text-align: right;
        }
        .m-form {
          margin-top: 1.2em;
        }
        .close:hover {
          cursor: pointer;
        }
      `}</style>
    </section>
  )
}

export default ItemEditor
