import { FunctionComponent, ReactNode } from "react"
import { Item } from "../../types"
//import { EmbedYoutube } from "../embed/embed-youtube"
import YouTube from "react-youtube"
import { TwitterTweetEmbed } from "react-twitter-embed"
import InstagramEmbed from "react-instagram-embed"
import TelegramEmbed from "react-telegram-embed"
//import TelegramEmbed from "./widget/embed/telegram-embed"
import React from "react"
import {
  shouldTransform as shouldTransformYouTube,
  getYouTubeIFrameSrc,
  /*getHTML as getYouTubeHTML,*/
} from "../../../node_modules/gatsby-remark-embedder/dist/transformers/YouTube"
import {
  shouldTransform as shouldTransformSoundCloud,
  getHTML as getSoundCloudHTML,
} from "../../../node_modules/gatsby-remark-embedder/dist/transformers/SoundCloud"
import {
  shouldTransform as shouldTransformSpotify,
  getHTML as getSpotifyHTML,
} from "../../../node_modules/gatsby-remark-embedder/dist/transformers/Spotify"
import {
  shouldTransform as shouldTransformTwitter,
  getHTML as getTwitterHTML,
} from "../../../node_modules/gatsby-remark-embedder/dist/transformers/Twitter"

import ReactHtmlParser from "react-html-parser"
import SmartlikeBlog from "./smartlike-blog"
import YoutubeLite from "./widget/embed/youtube-lite"
import { Tweet } from "react-twitter-widgets"
import TweetLite from "./widget/embed/tweet-lite"
import ReactPlayerLite from "./widget/embed/react-player-lite"
import ReactPlayer from "react-player"
import { hasProvider } from "oembed-parser"
import OembedLite from "./widget/embed/oembed-lite"
import MastodonLite from "./widget/embed/mastodon-lite"
import PeertubePlayerLite from "./widget/embed/peertube-player-lite"

export type Props = {
  children: {
    item: Item
    header: ReactNode
    content: ReactNode
    upperToolbar: ReactNode
    mainImage: ReactNode
    tags: ReactNode
    lowerToolbar: ReactNode
    footer?: ReactNode
  }
}

export enum EmbedType {
  General = 1,
  YouTube,
  Twitter,
  Instagram,
  Telegram,
  Smartlike,
  ReactPlayer,
  Spotify,
  Oembed,
  Mastodon,
  PeerTube,
}

function getContentType(target: string, meta: any): EmbedType {
  if (shouldTransformTwitter(target)) return EmbedType.Twitter
  else if (ReactPlayer.canPlay(target)) return EmbedType.ReactPlayer
  else if (shouldTransformSpotify(target)) return EmbedType.Spotify
  else if (hasProvider(target)) return EmbedType.Oembed

  let parts = target.split(`/`)
  //console.log(parts[2])
  if (parts.length > 2) {
    if (
      parts[2].indexOf("instagram.com") == 0 ||
      parts[2].indexOf("www.instagram.com") == 0
    )
      return EmbedType.Instagram
    else if (parts[2].indexOf("t.me") == 0) return EmbedType.Telegram
    else if ("platform" in meta) {
      if (
        meta["platform"] == "mastodon" &&
        meta["embed"] &&
        meta["embed"].length != 0
        /*(target.match(/https\:\/\/[a-zA-Z0-9\-.]+\/@[a-zA-Z0-9_]+\/[0-9]+/) ||
          target.indexOf("/users/") != -1 ||
          target.indexOf("/statuses/") != -1)*/
      )
        return EmbedType.Mastodon
      else if (meta["platform"] == "peertube") {
        return EmbedType.PeerTube
      }
    } else if (
      (parts[2].indexOf("smartlike.org") == 0 &&
        parts.length > 3 &&
        parts[3] == "blog") ||
      parts[2].indexOf("blog.smartlike.org") == 0 ||
      parts[2].indexOf("examples.smartlike.org") == 0
    )
      return EmbedType.Smartlike
  }
  return EmbedType.General
}

function getYoutubeId(str: string) {
  let parts = str.split(`/`)
  let pars = parts.pop()
  if (pars) {
    if (pars.indexOf(`?v=`) > -1) {
      pars = str.split(`?v=`)[1]
      pars = pars.split(`?`)[0]
      pars = pars.split(`&`)[0]
      return pars
    }
  }
  return ""
}

function getTwitterId(str: string) {
  let parts = str.split(`/`)
  return parts.pop()
}

const PanelImage: FunctionComponent<Props> = ({ children }) => {
  const contentType = getContentType(children.item.url, children.item)
  //console.log("contentType " + contentType + " " + children.item.url)
  const twitterId =
    contentType == EmbedType.Twitter ? getTwitterId(children.item.url) : ""

  return (
    <div>
      <div className="panel">
        <div className="padded" style={{ paddingBottom: 0 }}>
          <div className="top row">
            <div className="row expanding header">{children.header}</div>
            <div className="row fixed">{children.upperToolbar}</div>
          </div>
          {contentType == EmbedType.General && <div>{children.content}</div>}
        </div>

        {/*contentType == EmbedType.YouTube && (
          <YoutubeLite
            title={children.item.title}
            url={children.item.url}
            thumbnail={children.item.image}
          />
        )*/}

        {contentType == EmbedType.Oembed && (
          <OembedLite
            title={children.item.title}
            url={children.item.url}
            thumbnail={children.item.image}
            embed={children.item.embed}
          />
        )}

        {contentType == EmbedType.Mastodon && (
          <MastodonLite
            title={children.item.title}
            url={children.item.url}
            thumbnail={children.item.image}
            embed={children.item.embed}
          />
        )}

        {(contentType == EmbedType.ReactPlayer ||
          contentType == EmbedType.Spotify) && (
          <ReactPlayerLite
            title={children.item.title}
            url={children.item.url}
            thumbnail={children.item.image}
            embedType={contentType}
          />
        )}

        {contentType == EmbedType.PeerTube && (
          <PeertubePlayerLite
            title={children.item.title}
            url={children.item.url}
            thumbnail={children.item.image}
          />
        )}

        {/*
        {embededHTML.length != 0 && (
          <div className="embedded-frame embedded-frame-silent">
            {ReactHtmlParser(embededHTML)}
          </div>
        )}
        */}
        {contentType == EmbedType.Twitter && (
          <div className="centerContent embedded-frame embedded-frame-silent">
            {/*<TwitterTweetEmbed tweetId={twitterId} />
            <Tweet tweetId={twitterId} />*/}
            {children.item.embed ? (
              <TweetLite
                id={children.item.id}
                url={children.item.url}
                embed={children.item.embed}
              />
            ) : (
              <TwitterTweetEmbed tweetId={twitterId} />
            )}
          </div>
        )}

        {contentType == EmbedType.Instagram && (
          <div>
            <InstagramEmbed
              url={children.item.url}
              maxWidth={640}
              hideCaption={false}
              containerTagName="div"
              protocol=""
            />
          </div>
        )}

        {contentType == EmbedType.Telegram && (
          <div className="centerContent embedded-frame embedded-frame-silent">
            <TelegramEmbed src={children.item.url} />
          </div>
        )}

        {contentType == EmbedType.Smartlike && (
          <div className="centerContent">
            <SmartlikeBlog
              title={children.item.title}
              description={children.item.description}
              image={children.item.image}
              link={children.item.url}
            />
          </div>
        )}

        {contentType == EmbedType.General && (
          <div style={{ paddingTop: "10px" }}>{children.mainImage}</div>
        )}

        <div>{children.tags}</div>

        <div className="padded">
          {children.footer && <div className="row">{children.footer}</div>}
        </div>

        <style jsx>{`
          .iframe-container {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 0;
            height: 0;
            overflow: hidden;
          }
          .iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .player-wrapper {
            position: relative;
            padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
          }

          .react-player {
            position: absolute;
            top: 0;
            left: 0;
          }
          .embedded-frame {
          }

          @media (hover: none), (pointer: coarse) {
            .embedded-frame-silent {
              pointer-events: none;
            }
          }

          .panel {
            width: 100%;
          }
          .panel > *:not(:first-child) {
            /*margin-top: 1em;*/
          }
          .header {
            padding-bottom: 10px;
          }
          .padded {
            padding: 20px;
          }
          .row {
            display: flex;
            min-width: 0;
          }
          .row > :global(*:not(:first-child)) {
            margin-left: 1em;
          }
          .column {
            display: flex;
            flex-direction: column;
            min-width: 0;
          }
          .column > :global(*:not(:first-child)) {
            margin-top: 1em;
          }
          .top {
            justify-content: space-between;
            align-items: flex-start;
          }
          .bottom {
            justify-content: space-between;
            align-items: center;
          }
          .expanding {
            flex-shrink: 1;
          }
          .fixed {
            flex-shrink: 0;
          }
          .centerContent {
            text-align: -webkit-center;
            text-align: -moz-center;
          }
        `}</style>
      </div>
      <a
        className="link"
        href={children.item.url}
        target={children.item.url}
      ></a>
    </div>
  )
}

export default PanelImage
