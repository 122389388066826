import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import ReactHtmlParser from "react-html-parser"
import { useMedia } from "../../../../hooks/use-media"

export type Props = {
  id: string
  url: string
  embed: string
}

const TweetLite: FunctionComponent<Props> = ({ id, url, embed }) => {
  const { numColumns } = useMedia()
  const containerId = "tweet-" + id

  useEffect(() => {
    /*
    extract(url)
      .then(oembed => {
        console.log(oembed)
      })
      .catch(err => {
        console.trace(err)
      })
    */
    /*
    window.twttr = (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {}
      if (d.getElementById(id)) return t
      js = d.createElement(s)
      js.id = id
      js.src = "https://platform.twitter.com/widgets.js"
      fjs.parentNode.insertBefore(js, fjs)

      t._e = []
      t.ready = function (f) {
        t._e.push(f)
      }

      return t
    })(document, "script", "twitter-wjs")
*/
    //console.log(embed)
    /*
    setEmbededHTML(
      decodeURIComponent(
        '\u003Cblockquote class="twitter-tweet"\u003E\u003Cp lang="ru" dir="ltr"\u003EЯ горд, что в России первый в истории лауреат Нобелевской премии мира, который первым делом в новом статусе послал людей в жопу! \uD83D\uDE0D\uD83D\uDE0D\uD83D\uDE0D\u003C/p\u003E&mdash; так называемый наки (@michaelnacke) \u003Ca href="https://twitter.com/michaelnacke/status/1446942262680772614?ref_src=twsrc%5Etfw"\u003EOctober 9, 2021\u003C/a\u003E\u003C/blockquote\u003E\n'
      )
    )*/
    //setEmbededHTML(embed)
    twttr.widgets.load(document.getElementById(containerId))
    //twttr.widgets.load()
    /*
      decodeURIComponent(
        '\u003Cblockquote class="twitter-tweet"\u003E\u003Cp lang="de" dir="ltr"\u003EAus diesen Ländern kommen die meisten wirtschaftlichen Eigentümer in den \u003Ca href="https://twitter.com/hashtag/PandoraPapers?src=hash&amp;ref_src=twsrc%5Etfw"\u003E#PandoraPapers\u003C/a\u003E \u003Ca href="https://t.co/IZMOaBgqVw"\u003Epic.twitter.com/IZMOaBgqVw\u003C/a\u003E\u003C/p\u003E&mdash; Nils Naber (@nils_naber) \u003Ca href="https://twitter.com/nils_naber/status/1444747432882024458?ref_src=twsrc%5Etfw"\u003EOctober 3, 2021\u003C/a\u003E\u003C/blockquote\u003E\n'
      )
    )
    //setEmbededHTML(
    //  '<blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Я горд, что в России первый в истории лауреат Нобелевской премии мира, который первым делом в новом статусе послал людей в жопу! 😍😍😍</p>&mdash; так называемый наки (@michaelnacke) <a href="https://twitter.com/michaelnacke/status/1446942262680772614?ref_src=twsrc%5Etfw">October 9, 2021</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'
    //)
    /*
    fetch("https://publish.twitter.com/oembed?url=" + encodeURIComponent(url), {
      mode: "no-cors",
    })
      //.then(res => res.json())
      .then(j => {
        console.log(j.body)
        //setEmbededHTML(j["html"])
      })
      .catch(error => {
        console.log("json parsing error occured ", error)
      })*/
    /*
    var req = new XMLHttpRequest()
    req.responseType = "json"
    req.open(
      "GET",
      "https://publish.twitter.com/oembed?url=" + encodeURIComponent(url),
      true
    )
    req.onload = function () {
      var jsonResponse = req.response
      console.log(jsonResponse)
      // do something with jsonResponse
    }
    req.send(null)*/
  }, [url])

  return (
    <div className={numColumns == 1 ? "" : "padded"}>
      <div className="preview" id={containerId}>
        {embed && ReactHtmlParser(embed)}
      </div>
      <style jsx>{`
        .preview {
          font-size: 20px;
          font-weight: 400;
          text-align: left;
          width: 100%;
        }
        .padded {
          padding-left: 40px;
        }
      `}</style>
    </div>
  )
}

export default TweetLite
