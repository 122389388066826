import {
  FunctionComponent,
  useState,
  useCallback,
  ChangeEvent,
  FormEvent,
} from "react"
import { Channel, Item } from "../../types"
import Input from "../layout/form/input"
import Textarea from "../layout/form/textarea"
import { useUser, getCachedSecret } from "../../hooks/use-user"
import SubmitButton from "../layout/form/submit-button"
import React from "react"
import { useNode } from "../../hooks/use-node"
import { showToast } from "../../components/status-notification"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

export type Props = {
  channel: Channel
  onUpdate: (channel: Channel) => void
  onChange?: () => void
}

const ChannelEditor: FunctionComponent<Props> = ({
  channel,
  onUpdate,
  onChange,
}) => {
  const [title, setTitle] = useState(channel.title)
  const onTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value)
  }, [])

  const [image, setImage] = useState(channel.image || "")
  const [icon, setIcon] = useState(channel.icon || "")
  const { rpc } = useNode()

  const onImageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setImage(event.currentTarget.value)
      if (icon.length == 0) setIcon(event.currentTarget.value)
    },
    [icon]
  )

  const onIconChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIcon(event.currentTarget.value)
  }, [])

  const [description, setDescription] = useState(channel.description)
  const onDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(event.currentTarget.value)
    },
    []
  )

  const { user, updateBalance } = useUser()
  const [isLoading, setLoading] = useState(false)
  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()

      if (user && !isLoading) {
        setLoading(true)

        let editedChannel = channel
        channel.title = title
        channel.description = description
        channel.image = image
        channel.icon = icon

        let payload = {
          alias: channel.kind == 2 ? channel.alias : channel.id,
          id: channel.id,
          title: title,
          description: description,
          image: image,
          icon: icon,
        }

        rpc(
          "edit_channel",
          JSON.stringify(payload),
          user,
          getCachedSecret(),
          function (res) {
            let amount = 1
            let message
            if (res.status == "ok") {
              message = "Edit supplied"
              console.log(res.data.balance)
              onUpdate(editedChannel)
              if (user) updateBalance(-0.01)
            } else message = "Edit failed"

            showToast(faInfoCircle, "Smartlike network", message)
          }
        )

        setLoading(false)

        if (onChange) {
          onChange()
        }

        /*
          if (editedItem) {
            onUpdate(editedItem)
          }
          */
      }
    },
    [
      user?.id,
      channel.id,
      channel.title,
      channel.image,
      channel.icon,
      channel.description,
      onUpdate,
      title,
      image,
      icon,
      description,
      isLoading,
    ]
  )

  const header =
    channel.kind == 2
      ? channel.alias_name + " on " + channel.alias_host
      : channel.id

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            if (onChange) {
              onChange()
            }
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      <h1>{header}</h1>

      <form onSubmit={onSubmit}>
        <div>
          <h2>Title</h2>
          {channel.kind == 2 ? (
            <Input value={title} onChange={onTitleChange} disabled />
          ) : (
            <Input value={title} onChange={onTitleChange} />
          )}
        </div>

        <div>
          <h2>Image</h2>
          <Input value={image} onChange={onImageChange} />
        </div>

        <div>
          <h2>Icon</h2>
          <Input value={icon} onChange={onIconChange} />
        </div>

        <div>
          <h2>Description</h2>
          <Textarea
            value={description}
            onChange={onDescriptionChange}
            rows={6}
          />
        </div>

        <footer>
          <SubmitButton
            disabled={isLoading /* || !isValid*/}
            loading={isLoading}
          >
            Submit
          </SubmitButton>
        </footer>
      </form>

      <style jsx>{`
        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h2 {
          font-size: inherit;
        }
        h3 {
          font-size: inherit;
          font-weight: lighter;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
        footer {
          text-align: right;
        }
        .close {
          font-size: 14px;
        }
        .close:hover {
          cursor: pointer;
        }
      `}</style>
    </section>
  )
}

export default ChannelEditor
