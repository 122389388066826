import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import ReactHtmlParser from "react-html-parser"
import { useMedia } from "../../../../hooks/use-media"

export type Props = {
  id: string
  url: string
  embed: string
}

let scriptLoaded = false

const MastodonLite: FunctionComponent<Props> = ({ id, url, embed }) => {
  const { numColumns } = useMedia()
  const parsedURL = new URL(embed)

  /*
  const returnStr =
  '<iframe src="' + embed +
  '/embed" class="mastodon-embed" style="max-width: 100%; border: 0" width="400"></iframe><script src="https://' +
  parsedURL.host +
  '/embed.js" async="async"></script>'

*/
  /*
  var instanceAddress = ""
  var userId = ""
  var postId = ""
  var returnStr = ""

  if (url && url.match(/https\:\/\/[a-zA-Z0-9\-.]+\/@[a-zA-Z0-9_]+\/[0-9]+/)) {
    var urlVariable = url.match(
      /https\:\/\/([a-zA-Z0-9\-.]+)\/@([a-zA-Z0-9_]+)\/([0-9]+)/
    )
    if (urlVariable) {
      instanceAddress = urlVariable[1]
      userId = urlVariable[2]
      postId = urlVariable[3]
    }
  }

  //console.log("postId = " + postId)
  if (instanceAddress != "" && userId != "" && postId != "") {
    returnStr =
      '<iframe src="https://' +
      instanceAddress +
      "/@" +
      userId +
      "/" +
      postId +
      '/embed" class="mastodon-embed" style="max-width: 100%; border: 0" width="400"></iframe><script src="https://' +
      instanceAddress +
      '/embed.js" async="async"></script>'
  } else {
    returnStr = "<pre>hexo-tag-mastodon: Invalid URL Format</pre>"
  }
*/

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://mastodon.smartlike.org/embed.js"
    //script.src = "https://" + parsedURL.host + "/embed.js"
    script.async = true

    document.body.appendChild(script)

    return () => {
      //console.log("removing script")
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div>
      <div className="preview">
        <iframe
          src={embed + "/embed"}
          className={`mastodon-embed`}
          style={{ width: "100%", border: 0 }}
        ></iframe>
      </div>
      <style jsx>{`
        /*
        .mastodon-embed {
          width: 100%;
          height: 400px;
          border: 0;
        }*/
        .preview {
          font-size: 20px;
          font-weight: 400;
          text-align: left;
          width: 100%;
        }
        .padded {
          padding-left: 40px;
        }
      `}</style>
    </div>
  )
}

export default MastodonLite
