import {
  useState,
  useCallback,
  ChangeEvent,
  FunctionComponent,
  useRef,
  useEffect,
} from "react"
import { lightTheme, darkTheme } from "../../services/theme"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import React from "react"
import Avatar from "../layout/avatar"
import { useMedia } from "../../hooks/use-media"

type Props = {
  borderless?: boolean
  autofocus?: boolean
  placeholder?: string
  initialValue?: string
  commentId?: string
  onSubmit?: (amount: number, text: string, commentId: string) => void
  onCancel?: () => void
}

const CommentInput: FunctionComponent<Props> = ({
  onSubmit: submit,
  onCancel: cancel,
  borderless = false,
  autofocus = false,
  placeholder,
  initialValue = "",
  commentId = "",
}) => {
  const { user } = useUser()
  const { numColumns } = useMedia()

  const [text, setText] = useState(initialValue)
  const [rows, setRows] = useState(1)

  const onKeyPress = useCallback(
    (event: ChangeEvent<KeyboardEvent>) => {
      //console.log("onKeyPress ")
      if (event.charCode == 13 || event.key === "Enter") {
        if (!(event.shiftKey || event.ctrlKey) && text.length > 0) {
          if (submit) submit(user ? user.like_value : 0.01, text, commentId)
          setText("")
          setRows(1)
          event.preventDefault()
        } else if (event.ctrlKey) {
          setText(text + "\n")
          setRows(rows + 1)
        }
      }
    },
    [text, initialValue, user]
  )

  const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.currentTarget.value.length > 2000) return
    setText(event.currentTarget.value)
    const textareaLineHeight = 18

    const previousRows = event.target.rows
    event.target.rows = 1 // reset number of rows in textarea
    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight)

    if (currentRows === previousRows) {
      event.target.rows = currentRows
    }

    /*if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
		}*/

    setRows(currentRows)
  }, [])

  const onSubmit = useCallback(() => {
    if (submit) {
      submit(1, text, initialValue.length != 0)
    }
    setText("")
  }, [submit, text])
  const onCancel = useCallback(() => {
    if (cancel) {
      cancel()
    }
    setText("")
  }, [cancel])

  const ref = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (autofocus) {
      ref.current?.focus()
    }
  }, [])

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  return (
    <div className="component">
      <div className={"content " + (borderless ? "" : "bordered")}>
        {numColumns == 1 ? (
          <div className="link" style={{ display: "block" }}>
            <div style={{ display: "table", marginBottom: "5px" }}>
              <Avatar
                image={user.profile.image ?? undefined}
                label={user.profile.title}
                size={28}
              />
              <strong
                className="title"
                style={{ display: "table-cell", verticalAlign: "middle" }}
              >
                {user.profile.title}
              </strong>
            </div>
            {/*<TextareaAutosize placeholder={placeholder} />*/}
            <textarea
              rows={rows}
              ref={ref}
              placeholder={placeholder}
              value={text}
              onChange={onChange}
              onFocus={onChange}
              onKeyPress={onKeyPress}
            />
          </div>
        ) : (
          <div className="link">
            <Avatar
              image={user.profile.image ?? undefined}
              label={user.profile.title}
              size={28}
            />
            <strong className="title">{user.profile.title}</strong>
            {/*<TextareaAutosize placeholder={placeholder} />*/}
            <textarea
              rows={rows}
              ref={ref}
              placeholder={placeholder}
              value={text}
              onChange={onChange}
              onFocus={onChange}
              onKeyPress={onKeyPress}
            />
          </div>
        )}

        {text.length > 0 && (
          <div style={{ textAlign: "right", fontSize: "13px" }}>
            {text.length}/2000 Enter to send
          </div>
        )}

        {/*(submit || cancel) && (
          <div className="toolbar">
            {submit && (
              <span>
                <Button variant="active" onClick={onSubmit} disabled={!text}>
                  {submitLabel}
                </Button>
              </span>
            )}
            {cancel && (
              <span>
                <Button variant="transparent" onClick={onCancel}>
                  Cancel
                </Button>
              </span>
            )}
          </div>
            )*/}
      </div>

      <style jsx>{`
        .textArea {
          padding: 5px;

          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          font-size: 12px;
          width: 100%;
          color: inherit;
          min-height: 3.3em;
          line-height: 130%;
        }
        .title {
          white-space: nowrap;
          margin-left: 5px;
          margin-right: 5px;
        }
        .link {
          min-width: 50px;
          display: flex;
          align-items: center;
          text-decoration: none;
          font-family: Roboto;
          font-size: 14px;
        }

        .component {
          display: flex;
        }
        header {
          display: flex;
          align-items: center;
        }
        header > :not(:first-child) {
          margin-left: 0.5em;
        }
        .content {
          flex: 1;
        }
        .content > :not(:first-child) {
          margin-top: 1em;
        }
        .bordered {
          padding-top: 1em;
          border-top: 1px solid ${css.borderColor};
          transition: 200ms border-color;
        }
        .toolbar {
          margin-top: 0.75em;
        }
        .toolbar span:not(:first-child) {
          margin-left: 0.1em;
        }
        .toolbar span:not(:last-child) {
          margin-right: 0.1em;
        }
        textarea {
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          font-size: 14px;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        textarea:focus {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }
      `}</style>
    </div>
  )
}

export default CommentInput
