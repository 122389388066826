import React, { useCallback, useState } from "react"
import { Comment, CommentTarget, LikeType } from "../types"
import { useFilters } from "./use-filters"
import { useIndexedDB } from "./use-indexeddb"
import { useLike } from "./use-like"
import { useUser } from "./use-user"

var demoCommentPublishMessages = [
  "Keep on writing!\nWhen people smartlike your posts you receive money.\n",
  "Amazing! Good post!\n",
  "Keep on posting clever comments and get rewarded!",
]

var demoDonateMessages = [
  "Thank you for your donation!\n",
  "Amazing! Many thanks!\n",
  "You make it possible.\nThank you!",
]

var demoCommentUpvoteMessages = [
  "Thank you!\nYou reward the author and help others discover the comment.\n",
  "Amazing! Good comment wins!",
  "Amazing! Good comment gets promoted!",
  "Great! Clever commentators are rewarded!",
]

var demoCommentDownvoteMessages = [
  "Let the good comments win!",
  "Thanks for helping curating comments!",
]

export const useComments = (
  target: CommentTarget | undefined,
  initialComments: Comment[],
  autoLoad: boolean,
  parentComment: string
) => {
  const { user, updateCommentLikes, isLoggedIn, getUserChannel } = useUser()
  const [comments, setComments] = useState(initialComments)
  const { muteComments } = useIndexedDB()
  const { filters } = useFilters()
  const { sendLike } = useLike()

  const publishComment = useCallback(
    async (amount: number, text: string, commentId: string) => {
      console.log("comment " + text)
      sendCommentAction(
        commentId,
        parentComment,
        commentId.length ? "edit" : "publish",
        amount,
        text,
        target.kind == LikeType.Donate || LikeType.DonateChannel
          ? demoDonateMessages
          : demoCommentPublishMessages,
        () => {
          let ts = Math.floor(new Date().getTime() / 1000)
          if (commentId.length == 0) {
            const newComment: Comment = {
              id: "" + new Date().getTime(),
              dht_key: "" + new Date().getTime(),
              text: text,
              amount: amount,
              score: 0,
              published_at: ts,
              comments_count: 0,
              author: getUserChannel(),
              comments: [],
            }
            setComments(comments => [newComment].concat(comments))
          } else {
            if (text.length == 0) hideComment(commentId)
            else {
              setComments(c =>
                comments.map(c => {
                  if (c.id !== commentId) return c
                  else {
                    c.text = text
                    c.edited = "edited"
                    c.published_at = ts
                    return c
                  }
                })
              )
            }
          }
        }
      )
    },
    [
      user?.profile.name,
      target,
      comments,
      setComments,
      getUserChannel,
      parentComment,
    ]
  )

  const hideComment = useCallback(
    (commentId: string) => {
      setComments(comments =>
        comments.filter(comment => comment.id !== commentId)
      )
    },
    [comments, setComments]
  )

  const upvoteComment = useCallback(
    async (commentId: string, amount: number) => {
      sendCommentAction(
        commentId,
        parentComment,
        "upvote",
        amount,
        "",
        demoCommentUpvoteMessages,
        () => {
          setComments(c =>
            comments.map(c => {
              if (c.id !== commentId) return c
              else {
                c.score += 1
                return c
              }
            })
          )
          console.log(comments?.length)
        }
      )
    },
    [user?.profile.name, comments, setComments]
  )

  const downvoteComment = useCallback(
    async (commentId: string, amount: number) => {
      sendCommentAction(
        commentId,
        parentComment,
        "downvote",
        amount,
        "",
        demoCommentDownvoteMessages,
        () => {
          setComments(c =>
            comments.map(c => {
              if (c.id !== commentId) return c
              else {
                c.score -= 1
                return c
              }
            })
          )
        }
      )
    },
    [user?.profile.name, comments, setComments]
  )

  const sendCommentAction = useCallback(
    (
      commentId: string,
      parentId: string,
      action: string,
      amount: number,
      text: string,
      messages: string[],
      onSuccess: () => void
    ) => {
      if (user) {
        console.log("sending comment " + target?.url + " " + parentId)

        const msg = {
          kind: target.kind,
          target: target.url,
          amount: amount,
          currency: "USD",
          payload: JSON.stringify({
            id: commentId && commentId.length ? commentId : undefined,
            parent_id: parentId && parentId.length ? parentId : undefined,
            action: action,
            text: text,
          }),
          /*id: Math.floor(Math.random() * 100000000),*/
        }

        sendLike(msg, null, messages, function () {
          onSuccess()
        })
      }
    },
    [target, user]
  )

  return {
    comments,
    setComments,
    publishComment,
    hideComment,
    upvoteComment,
    downvoteComment,
  }
}
