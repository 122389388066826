import * as React from "react"
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import ReactPlayer from "react-player/lazy"
import { uid } from "react-uid"
import { EmbedType } from "../../panel-image"
import ReactHtmlParser from "react-html-parser"

import { extract } from "oembed-parser"

export type Props = {
  url: string
  title: string
  thumbnail: string
  embed: string
}

const OembedLite: FunctionComponent<Props> = ({
  url,
  title,
  thumbnail,
  embed,
}) => {
  const [oembed, setOembed] = useState<string | null>(null)
  const [dimensions, setDimensions] = useState({
    maxwidth: 640,
    maxheight: 480,
  })
  const [width, setWidth] = useState(0)

  const ref = useRef(null)

  useEffect(() => {
    setDimensions({
      maxwidth: ref.current.clientWidth,
      maxheight: ref.current.clientHeight,
    })
  }, [])

  const play = useCallback(async () => {
    if (embed && embed.length > 0) {
      setOembed(embed)
      return
    }

    try {
      const oembed = await extract(url, dimensions)
      if (Object.prototype.hasOwnProperty.call(oembed, "html"))
        setOembed(oembed.html)
      else if (typeof window !== "undefined") {
        window.open(url, "_blank")
      }
    } catch (err) {
      console.trace(err)
      window.open(url, "_blank")
    }
  }, [dimensions])

  return (
    <div className="iframe-container" ref={ref} id={uid(url)}>
      {oembed == null ? (
        <>
          <div className={"title title-active"}>
            <div className="title-overflow">{title}</div>
          </div>

          <link rel="preload" href={thumbnail} as="image" />
          <article
            onClick={play}
            className={`yt-lite`}
            data-title={title}
            style={{
              backgroundImage: `url(${thumbnail})`,
              ...({
                "--aspect-ratio": `${(9 / 16) * 100}%`,
              } as React.CSSProperties),
            }}
          >
            <div className="lty-playbtn" aria-label={`${title}`} />
          </article>
        </>
      ) : (
        ReactHtmlParser(oembed)
      )}

      <style jsx>{`
        .react-player {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10000;
        }
        .title {
          z-index: -1;
          color: rgb(238, 238, 238);
          position: absolute;
          font-size: 18px;
          font-family: "Roboto";
          font-weight: 400;
          padding: 20px;
          width: 100%;
        }
        .title-active {
          z-index: 100;
        }
        .title-overflow {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .iframe-container {
          position: relative;
          padding-bottom: 56.25%;
          padding-top: 0;
          height: 0;
          overflow: hidden;
        }
        .iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .yt-lite {
          background-color: #000;
          position: relative;
          display: block;
          contain: content;
          background-position: 50%;
          background-size: cover;
          cursor: pointer;
        }
        .yt-lite:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
          background-position: top;
          background-repeat: repeat-x;
          height: 60px;
          padding-bottom: 50px;
          width: 100%;
          transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
        }
        .yt-lite:after {
          content: "";
          display: block;
          padding-bottom: var(--aspect-ratio);
        }
        .yt-lite > iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        .yt-lite > .lty-playbtn {
          width: 60px;
          height: 60px;
          background-color: #212121;
          z-index: 1;
          opacity: 0.8;
          border-radius: 50%;
          transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
        }
        .yt-lite:hover > .lty-playbtn {
          background-color: #ffd64e;
          opacity: 1;
        }
        .yt-lite > .lty-playbtn:before {
          content: "";
          border-color: transparent transparent transparent #fff;
          border-style: solid;
          border-width: 11px 0 11px 19px;
        }
        .yt-lite > .lty-playbtn,
        .yt-lite > .lty-playbtn:before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
        .yt-lite.lyt-activated {
          cursor: unset;
        }
        .yt-lite.lyt-activated:before,
        .yt-lite.lyt-activated > .lty-playbtn {
          opacity: 0;
          pointer-events: none;
        }
      `}</style>
    </div>
  )
}

export default OembedLite
