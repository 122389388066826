import {
  faSave,
  faTags,
  faQuestionSquare,
  faWindowClose,
  faCheckSquare,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButterToast from "butter-toast"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Tag, WithContext as ReactTags } from "react-tag-input"
import Notification from "../components/notification"
import { useFilters } from "../hooks/use-filters"
import { useMedia } from "../hooks/use-media"
import { getCachedSecret, useUser } from "../hooks/use-user"
import { useNode } from "../hooks/use-node"
import HtmlTooltip from "./widgets/html-tooltip"

const markThankuMessages = [
  "Thank you for helping others find great content!",
  "Thank you for helping others find great content!\nYou are awesome!",
  "You helped a lot!\n",
  "Amazing! Thank you!\n",
  "Cool! Thanks.",
  "You've just made the world better!",
  "You've just helped to sort out the Internet!",
  "The Internet just got better!",
  "Thank you!",
]

type Props = {
  channelid: string | null
  contentid: string | null
  creator: string | null
  tags: string[]
  channel_account: boolean | null
  onModeration?: (tags: string[]) => void | undefined
}

const TagEditor: FunctionComponent<Props> = ({
  channelid,
  contentid,
  creator,
  tags,
  channel_account,
  onModeration,
}) => {
  const [showTags, setShowTags] = useState(tags)
  const [suggestions, setSuggestions] = useState([])
  const { filters, updateFilters } = useFilters()
  const { user, updateBalance, accountState } = useUser()
  const [showEditor, setShowEditor] = useState(false)
  const [editorTags, setEditorTags] = useState([])
  const { isTouchDevice } = useMedia()
  const { rpc } = useNode()

  const maxTags = isTouchDevice ? 7 : 12

  useEffect(() => {
    setShowTags(tags.slice(0, maxTags))
  }, [tags])

  const filterByTag = useCallback(
    async (tag: string) => {
      if (filters && filters.tags && filters.tags.indexOf(tag) == -1) {
        let tags = filters.tags.slice()
        tags.push(tag)
        updateFilters({ tags: tags })
      }
    },
    [filters]
  )

  const handleDelete = useCallback(
    (i: number) => {
      if (i >= 0) {
        setSuggestions(suggestions => suggestions.concat([editorTags[i]]))
        let newTags = editorTags.slice()
        newTags.splice(i, 1)
        setEditorTags(newTags)
      }
    },
    [editorTags, suggestions]
  )

  const handleDrag = useCallback(
    (tag, currPos, newPos) => {
      const tags = [...editorTags]
      const newTags = editorTags.slice()

      newTags.splice(currPos, 1)
      newTags.splice(newPos, 0, tag)

      // re-render
      setEditorTags(newTags)
    },
    [editorTags]
  )

  const handleAddition = useCallback(
    (tag: Tag) => {
      console.log(tag)
      editorTags.push(tag)
      setEditorTags(editorTags)
      setSuggestions(suggestions.filter(s => s.id != tag.id))
    },
    [editorTags, suggestions]
  )

  const edit = useCallback(async () => {
    if (accountState < 0) return

    let t = []
    for (var i = 0; i < tags.length; i++) t.push({ id: tags[i], text: tags[i] })
    setEditorTags(t)
    setShowEditor(true)

    const response = await Promise.resolve(
      fetch("/api/v2/tags/" + (contentid ? contentid : channelid))
        .then(res => res.text())
        .catch(error => {
          console.log("json parsing error occured ", error)
          return null
        })
    )

    console.log(response)
    if (response && response.length) {
      let tags = response.split(",")
      let s = []
      for (var i = 0; i < tags.length; i++)
        s.push({ id: tags[i], text: tags[i] })
      setSuggestions(s)
    } else {
      let t = []
      for (var i = 0; i < showTags.length; i++)
        t.push({ id: showTags[i], text: showTags[i] })
      setSuggestions(t)
    }
  }, [showTags])

  const closeEditor = useCallback(() => {
    setShowEditor(false)
  }, [])

  const moderationCheck = useCallback(() => {
    console.log(editorTags + " - " + showTags)
    save(showTags)
  }, [showTags])

  const save = useCallback(
    (tags: string[]) => {
      if (tags.length == 0) {
        for (var i = 0; i < editorTags.length; i++)
          tags.push(editorTags[i].text.toLowerCase())
      }

      const msg = {
        id: contentid ? contentid : channelid,
        tags: tags.join(","),
        channel_account: channel_account != null ? true : undefined,
      }
      const data = JSON.stringify(msg)
      setShowTags(tags)

      rpc("edit_tags", data, user, getCachedSecret(), function (res) {
        if (res.status == "ok") {
          if (user) updateBalance(-0.01 * user.displayed_currency_rate)
          const message =
            markThankuMessages[
              Math.floor(Math.random() * markThankuMessages.length)
            ]
          import(
            "../images/toast/" + (Math.floor(Math.random() * 8) + 1) + ".png"
          ).then(image => {
            const c = React.createElement(
              Notification,
              {
                image: image.default,
                creator: creator ? creator : "",
                text: message,
              },
              null
            )

            ButterToast.raise({
              content: c,
              timeout: 3000,
            })
          })
        } else {
          console.error("Item smartliking failed")
        }
      })

      setShowEditor(false)
      if (onModeration) onModeration(showTags)
    },
    [editorTags, showTags, user]
  )

  return (
    <div className="tag-container">
      {!showEditor && (
        <div style={{ display: "flex", flexFlow: "wrap" }}>
          <HtmlTooltip title="Edit tags" placement="top">
            <div className={accountState >= 0 ? "action" : "local-action"}>
              <FontAwesomeIcon icon={faTags} onClick={edit} />
            </div>
          </HtmlTooltip>
          {onModeration && (
            <div
              className={accountState >= 0 ? "action" : "local-action"}
              style={{ paddingLeft: "5px" }}
            >
              <FontAwesomeIcon icon={faCheckSquare} onClick={moderationCheck} />
            </div>
          )}
          {showTags && Array.isArray(showTags) && showTags.length > 0 && (
            <>
              {showTags.map((t, index) => (
                <div
                  key={t + index}
                  className="tag"
                  onClick={() => filterByTag(t)}
                >
                  {t}
                </div>
              ))}
            </>
          )}
        </div>
      )}

      {showEditor && (
        <>
          <div style={{ display: "table-cell", paddingLeft: "25px" }}>
            <div style={{ display: "flex" }}>
              <div className="action" style={{ paddingLeft: "0px" }}>
                <FontAwesomeIcon icon={faSave} onClick={() => save([])} />
              </div>
              <div className="action" style={{ paddingLeft: "0px" }}>
                <FontAwesomeIcon icon={faWindowClose} onClick={closeEditor} />
              </div>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        marginBottom: "5px",
                      }}
                    >
                      Collaborative tagging
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: 400 }}>
                      <p>
                        If you like to help others find this item, add your tags
                        or choose from popular user-defined tags for this item.
                      </p>
                      <p>
                        Edits are coupled with a 1 cent donation to item owner
                        to mitigate spam.
                      </p>
                    </div>
                  </React.Fragment>
                }
                placement="top"
              >
                <div className="action" style={{ paddingLeft: "0px" }}>
                  <FontAwesomeIcon icon={faQuestionSquare} />
                </div>
              </HtmlTooltip>
            </div>
            <ReactTags
              tags={editorTags}
              suggestions={suggestions}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              inline={true}
              placeholder="new tag + enter/bksp"
              minQueryLength={0}
              shouldRenderSuggestions={(query: string) => true}
              handleFilterSuggestions={(
                textInputValue: string,
                possibleSuggestionsArray: Tag[]
              ) => {
                return suggestions
              }}
            />
          </div>
        </>
      )}

      <style jsx>
        {`
          .tag-container {
            display: flex;
            width: 100%;
          }
          .tag {
            background-color: rgb(105, 105, 105, 0.05);
            padding: 5px;
            margin: 2px;
            border-radius: 5px;
            font-size: 12px;
            user-select: none;
          }
          .tag:hover {
            background-color: rgb(105, 105, 105, 0.2);
            cursor: pointer;
          }
          .action,
          .local-action {
            padding-left: 25px;
            padding-right: 25px;
            text-align: left;
            line-height: 28px;
            opacity: 0.7;
            width: 25px;
            color: rgb(129, 129, 129);
          }
          .action:hover {
            cursor: pointer;
            color: blue;
          }
        `}
      </style>
    </div>
  )
}

export default TagEditor
